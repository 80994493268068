import jQuery from 'jquery/dist/jquery';
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";

export default class ReportingTicketList extends DefaultController {
    tables: any;
    elements: any;
    tableNames: any;
    columns: any;
    //@ts-ignore
    private initComplete: boolean;

    async init() {
        this.initComplete = false;
        this.tableNames = ["tickets"]
        this.bindListeners();
        this.setFilters();
        await this.loadTables();
        await this.configureColumns();
    }

    getEntityData(elem: any) {
        return {}
    }

    loadFilters() {
        const tableName = "tickets"
        this.tables[tableName].columns().every((col: any) => {
            if (col !== 0) {
                const column = this.tables[tableName].column(col);
                const header = document.querySelector(`div.table_toggler[data-column='${col}']`) as HTMLElement
                let filterElem: any = null
                if (header) {
                    if (col !== 0 && col !== 2 && col !== 7 && col <= 14) {
                        if (col === 13 || col === 14) {
                            filterElem = document.createElement("div")
                            filterElem.classList.add("row")
                            filterElem.innerHTML = `<div class="col-6" style="padding-right: 2px;"><input type="date" data-date="from" class="form-control" /> </div><div class="col-6" style="padding-left: 2px;"><input type="date" data-date="to" class="form-control" /> </div>`
                            filterElem.querySelectorAll("input").forEach((elem: any) => {
                                elem.addEventListener("change", (e: any) => {
                                    const parent = elem.parentNode;
                                    const from = filterElem.querySelector("input[data-date='from']")?.value;
                                    const to = filterElem.querySelector("input[data-date='to']")?.value;
                                    console.log(parent, from, to)
                                    if (from && to) {
                                        const fromDate = new Date(Date.parse(from));
                                        const toDate = new Date(Date.parse(to));
                                        if (fromDate < toDate) {
                                            const diffDays = Utils.getDaysArray(fromDate, toDate);
                                            console.log("AAAA", fromDate, toDate, diffDays)
                                            column.search(diffDays.join("|").length > 0 ? '^' + diffDays.map(d => d.toLocaleDateString()).join("|") + '$' : '', true, false).draw();
                                        }
                                    }
                                })
                            })
                        } else {
                            filterElem = document.createElement('select');
                            filterElem.classList.add("form-select")
                            filterElem.multiple = true
                            filterElem.addEventListener("click", (e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                            })
                        }
                        //@ts-ignore
                        header.querySelector(".accordion-body").appendChild(filterElem);
                    }
                }
                const options: any = {}
                this.tables[tableName].data().unique().sort().each((d: any, j: any) => {
                    if (col === 2) {
                    } else if (col === 1) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.ticket_category ? d.ticket_category.name : "");
                    } else if (col === 3) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.customer ? d.customer.name : "");
                    } else if (col === 4) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.customer_location ? d.customer_location.name : "");
                    } else if (col === 5) {
                        if (!options[col]) {
                            options[col] = ['']
                        }
                        options[col].push(d.customer_contact ? d.customer_contact.name : "");
                    } else if (col === 6) {
                        if (!options[col]) {
                            options[col] = ['']
                        }
                        options[col].push(d.project ? d.project.name : "");
                    } else if (col === 7) {
                    } else if (col === 8) {
                    } else if (col === 9) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.assignee ? d.assignee.name : "");
                    } else if (col === 10) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.calendar_appointments.length === 0 ? "Nein" : "Ja");
                    } else if (col === 11) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.status.name);
                    } else if (col === 12) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.priority.name);
                    }  else if (col === 13) {

                    }  else if (col === 14) {

                    }
                });
                Object.keys(options).forEach((key: any) => {
                    const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index).filter((n: any) => n).sort();
                    v.forEach((value: any) => {
                        if (filterElem) {
                            filterElem.add(new Option(value, value));
                        }
                    })
                })

                const savedFilters = JSON.parse(localStorage.getItem(`tickware_export_filter_${tableName}`) as string)
                if (savedFilters && savedFilters[`col-${col}`]) {
                    const values = savedFilters[`col-${col}`].split("|")
                    values.forEach((v: any) => {
                        if (filterElem.querySelector(`option[value='${v}']`)) {
                            filterElem.querySelector(`option[value='${v}']`).setAttribute("selected", "true")
                        }
                    })
                    const ev = new Event("change")
                    filterElem.dispatchEvent(ev)
                }
                jQuery(filterElem).on("click", (e: any) => {
                    e.preventDefault();
                    e.stopPropagation()
                });
                if (filterElem && filterElem.nodeName === "SELECT") {
                    jQuery(filterElem).select2().on('select2:select select2:unselect', (e) => {
                        console.log("filterElem", filterElem)
                        //@ts-ignore
                        const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {return jQuery.fn.DataTable.util.escapeRegex(e.value)})
                        let savedFilters = JSON.parse(localStorage.getItem(`tickware_export_filter_${tableName}`) as string)
                        if (!savedFilters) {
                            savedFilters = {}
                        }
                        savedFilters[`col-${col}`] = values.join("|")
                        localStorage.setItem(`tickware_export_filter_${tableName}`, JSON.stringify(savedFilters))
                        if (col === 6 && values.indexOf("Ja") === -1) {
                            column.search('^((?!Nein).)*$', true, false).draw()
                        } else if (col === 5 && values.indexOf('Nicht zugewiesen') === -1) {
                            column.search('', true, false).draw()
                        } else {
                            column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                        }
                    });
                }
            }
        });
    }

    async loadTables() {
        this.elements = {};
        this.tables = {};
        this.columns = {};
        await this.loadTicketTable();
    }

    setFilters() {
        this.tableNames.forEach((tableName: string) => {
            let currentStorage = localStorage.getItem(`tickware_export_settings_${tableName}`)
            if (!currentStorage) {
                currentStorage = '{}'
            }
            let currentStorageO = JSON.parse(currentStorage)
            Object.keys(currentStorageO).forEach((storageIndex: any) => {
                const storageValue = currentStorageO[storageIndex]
                let htmlElement = document.querySelector(`li[data-table='${tableName}'][data-column='${storageIndex}']`)
                if (htmlElement) {
                    const icon = htmlElement.querySelector("i") as HTMLElement;
                    if (storageValue) {
                        icon.classList.remove("ti-eye-off")
                        icon.classList.add("ti-eye")
                    } else {
                        icon.classList.add("ti-eye-off")
                        icon.classList.remove("ti-eye")
                    }
                }
            })
        })
    }

    async configureColumns() {
        this.tableNames.forEach((tableName: string) => {
            let currentStorage = localStorage.getItem(`tickware_export_settings_${tableName}`)
            if (!currentStorage) {
                currentStorage = '{}'
            }
            let currentStorageO = JSON.parse(currentStorage)
            Object.keys(currentStorageO).forEach((storageIndex: any) => {
                const storageValue = currentStorageO[storageIndex]
                const table = this.tables[tableName]
                let column = table.column(storageIndex);
                column.visible(storageValue);
            })
        })
    }

    async loadTicketTable() {
        const tableName = "tickets"
        this.columns[tableName] = []
        this.elements[tableName] = jQuery(`.table-${tableName} table`);
        this.tables[tableName] = this.elements[tableName].DataTable({
            deferRender: false,
            pageLength: 25,
            colReorder: true,
            ordering: false,
            initComplete: (r: any) => {
                this.elements[tableName].closest(".card").find(".loading-body").addClass("d-none")
                this.elements[tableName].closest(".card").find(".card-datatable").removeClass("d-none")
                this.loadFilters();
                if (!this.initComplete) {
                    this.initComplete = true
                    Utils.hideLoader();
                }
            },
            ajax: `/api/v1/${tableName}/reporting`,
            order: [[0, 'desc']],
            columns: [
                {data: 'number'},
                {data: 'uuid'},
                {data: 'title'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.ticket_category ? full.ticket_category.name : "";
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.customer) {
                            return `${full.customer.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.customer_location) {
                            return `${full.customer_location.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.customer_contact) {
                            return `${full.customer_contact.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 6,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.project) {
                            return `${full.project.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 7,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.customer_reference;
                    },
                },
                {
                    targets: 8,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.orders) {
                            return `${full.orders.map((o: any) => {return o.number}).join(", ")}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 9,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.assignee) {
                            return `${full.assignee.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 10,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str = "";
                        if (full.calendar_appointments.length === 0) {
                            str =  "Nein";
                        } else {
                            full.calendar_appointments.forEach((calendar_appointment: any) => {
                                str += `<span style="font-size: 10px"> ${calendar_appointment.from}</span><br />`
                            })
                        }

                        return str;
                    },
                },
                {
                    targets: 11,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.status) {
                            return `${full.status.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 12,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.priority) {
                            return `<span style="background-color:${full.priority.color};width: 100%; display: block; height: 100%; padding: 4px; text-align: center; color: #FFF;">${full.priority.name}</span>`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 13,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return new Date(Date.parse(`${full.created_at}`)).toLocaleString();
                    },
                },
                {
                    targets: 14,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.closed_at;
                    },
                },

                {
                    targets: 15,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let km = 0;
                        full.reports.forEach((report: any) => {
                            km += parseInt(report.arrival_distance)
                        })

                        return km;
                    },
                },

                {
                    targets: 16,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let min = 0;
                        full.reports.forEach((report: any) => {
                            min += parseInt(report.arrival_time)
                        })

                        return min;
                    },
                },

                {
                    targets: 17,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let min = 0;
                        full.reports.forEach((report: any) => {
                            min += parseInt(report.spent_time)
                        })

                        return min;
                    },
                },

                {
                    targets: 18,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let km = 0;
                        full.reports.forEach((report: any) => {
                            km += parseInt(report.return_distance)
                        })

                        return km;
                    },
                },

                {
                    targets: 19,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let min = 0;
                        full.reports.forEach((report: any) => {
                            min += parseInt(report.return_time)
                        })

                        return min;
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `${Utils.translate('generic.download')}`,
                    className: 'dt-button btn btn-primary m-2 download-btn',
                    attr: {
                        "data-table": tableName
                    }
                }
            ]
        });

        this.columns[tableName] = this.tables[tableName].columns()[0];
        this.tables[tableName].on('column-reorder', (e: any, settings: any, details: any) => {
            this.columns[tableName] = Utils.arrayMove(this.columns[tableName], details.from, details.to)
            localStorage.setItem(`tickware_export_columns_${tableName}`, JSON.stringify(this.columns[tableName]))
        });
        const columnMapping = localStorage.getItem(`tickware_export_columns_${tableName}`)
        if (columnMapping) {
            const columnMappingO = JSON.parse(columnMapping)
            this.tables[tableName].colReorder.order(columnMappingO);
            this.columns[tableName] = columnMappingO
        }
    }

    bindListeners() {
        (document.querySelector("body") as HTMLElement).addEventListener("click", async (e: any) => {
            let target = e.target as HTMLElement;
            let element: any = null;
            if (target && target.classList.contains("download-btn")) {
                element = target
            //@ts-ignore
            } else if(target && target.parentNode.classList.contains("download-btn")) {
                element = target.parentNode
            }
            if(element) {
                const tableName = element.getAttribute("data-table") as string;
                const table = this.tables[tableName];
                if (table) {
                    let csv = '';
                    const csvHeader: any = []
                    table.columns().header().each((column: any, i: number) => {
                        const visible = table.column(i).visible();
                        if (visible) {
                            let content = column.innerHTML
                            if (content.indexOf("<span") !== -1) {
                                content = content.match(/>(.*)<\/span><span/)[1]
                            }
                            csvHeader.push(content)
                        }
                    })
                    csv += `${csvHeader.join(";")}\n`
                    table.rows({search:'applied'}).every((rowIndex: number) => {
                       let data: any = [];
                       const row = table.rows(rowIndex)
                            table.columns().every((colIndex: number) => {
                                const col = table.column(colIndex)
                                const visible = col.visible()
                                if (visible) {
                                    let content = table.cell(rowIndex,colIndex).node() ? table.cell(rowIndex,colIndex).node().innerHTML : "";
                                    if (content.indexOf("<span") !== -1) {
                                        content = content.match(/>(.*)<\/span/)[1]
                                    }
                                    data.push(content.replaceAll("&amp;", "&").replaceAll(";", ""))
                                }
                            });
                        csv += `${data.join(";")}\n`
                    });
                    await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
                }
            }
        });

        document.querySelectorAll('div.table_toggler').forEach((el) => {
            el.addEventListener('click',  (e) => {
                e.preventDefault();
                //@ts-ignore
                if (e.target && e.target.nodeName !== "LI" && e.target.nodeName !== "I" ) {
                    return;
                }
                const target = e.currentTarget as HTMLElement;
                let columnIdx = target.getAttribute('data-column') as string;
                let tableName = target.getAttribute('data-table') as string;
                const table = this.tables[tableName];
                if (table) {
                    const actualColumnIdx = this.columns[tableName].indexOf(parseInt(columnIdx))
                    let column = table.column(actualColumnIdx);
                    column.visible(!column.visible());
                    const icon = target.querySelector("i") as HTMLElement;
                    if (column.visible()) {
                        icon.classList.remove("ti-eye-off")
                        icon.classList.add("ti-eye")
                    } else {
                        icon.classList.add("ti-eye-off")
                        icon.classList.remove("ti-eye")
                    }
                    let currentStorage = localStorage.getItem(`tickware_export_settings_${tableName}`)
                    if (!currentStorage) {
                        currentStorage = '{}'
                    }
                    let currentStorageO = JSON.parse(currentStorage)
                    currentStorageO[columnIdx] = column.visible()
                    localStorage.setItem(`tickware_export_settings_${tableName}`, JSON.stringify(currentStorageO))
                }
            });
        });
        (document.querySelector("#tickets_export_from") as HTMLInputElement).addEventListener("change", (e) => {
            this.updateContent("tickets")
        });
        (document.querySelector("#tickets_export_to") as HTMLInputElement).addEventListener("change", (e) => {
            this.updateContent("tickets")
        });
    }

    async updateContent(tableName: string) {
        const from = (document.querySelector(`#${tableName}_export_from`) as HTMLInputElement).value;
        const to = (document.querySelector(`#${tableName}_export_to`) as HTMLInputElement).value;
        const table = this.tables[tableName]
        let currentUrl = table.ajax.url();
        if (currentUrl.indexOf("?") !== -1) {
            currentUrl = currentUrl.split("?")[0]
        }
        const newUrl = `${currentUrl}?from=${btoa(from)}&to=${btoa(to)}`
        this.elements[tableName].closest(".card").find(".loading-body").removeClass("d-none")
        this.elements[tableName].closest(".card").find(".card-datatable").addClass("d-none")
        await table.ajax.url(newUrl).load(() => {
            this.elements[tableName].closest(".card").find(".loading-body").addClass("d-none")
            this.elements[tableName].closest(".card").find(".card-datatable").removeClass("d-none")
        })
    }
}